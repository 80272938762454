<template>
    <div>
        <div class="game-view">
            <div class="game-view__splash">
                <div class="game-view__splash-content" v-show="isSplashShowed">
                    <picture>
                        <source srcset="@/assets/img/logo.webp" type="image/webp">
                        <source srcset="@/assets/img/logo.png" type="image/png">
                        <img class="game-view__logo" src="@/assets/img/logo.png" alt="metafighter logo">
                    </picture>
                    <div class="game-view__progress-text">
                        <span class="game-view__progress__value">{{ progressPercent }}</span> %
                    </div>
                    <div class="game-view__progress">
                        <div class="game-view__progress-bar" :style="{ width:progressPercent + '%' }"></div>
                    </div>

                    <div class="game-view__loading-note">
                        <p>Loading...MetaFighter Unleashed!</p>
                        <p>Legends Are Born in Battle. Be ready for the Fight!</p>
                    </div>
                </div>
            </div>
            <Unity :unity="unityContext" :width="width" :height="height"/>
        </div>
        <div class="game-footer">
          <span @click="setFullScreen" class="expand">
              <img src="../../assets/img/expand.svg?inline">
          </span>
        </div>
    </div>
</template>

<script>
import UnityWebgl from 'unity-webgl'

import {mapGetters} from "vuex";

export default {
    name: "GameBody",
    components: {
        Unity: UnityWebgl.vueComponent,
    },
    data() {
        return {
            unityContext: new UnityWebgl({
                loaderUrl: `${process.env.VUE_APP_BUCKET_URL}/MetaFighter_Release.loader.js`,
                dataUrl: `${process.env.VUE_APP_BUCKET_URL}/MetaFighter_Release.data.${process.env.VUE_APP_UNITY_FILE_EXTENSION}`,
                frameworkUrl: `${process.env.VUE_APP_BUCKET_URL}/MetaFighter_Release.framework.js.${process.env.VUE_APP_UNITY_FILE_EXTENSION}`,
                codeUrl: `${process.env.VUE_APP_BUCKET_URL}/MetaFighter_Release.wasm.${process.env.VUE_APP_UNITY_FILE_EXTENSION}`,
                streamingAssetsUrl: `${process.env.VUE_APP_BUCKET_URL}/StreamingAssets`,
                companyName: "MetaFighter",
                productName: "Meta Fighter",
                productVersion: "1.0",
            }),
            width: null,
            height: null,
            progress: 0.0,
            isUnityLoaded: false,
            wallet: null,
        };
    },
    computed: {
        ...mapGetters(['account']),
        ...mapGetters('contract', ['isCorrectNetwork']),

        progressPercent() {
            return (100 * this.progress).toFixed(2)
        },

        isSplashShowed() {
            return this.progress !== 1
        },

        correctAccount() {
            return this.isCorrectNetwork ? this.account : '';
        },
        authToken() {
            return this.$store.getters['auth/token'];
        },
    },
    created() {
        this.width = '900px';
        this.height = '600px';
        this.$router.history.listen((newLocation) => {
            window.location.href = newLocation.path;
        })
    },

    mounted() {
        this.unityContext.on('GetWalletData', this.getWalletDataEvent);
        this.unityContext.on('GetUsername', this.getUsernameEvent);
        this.unityContext.on('GetAccessToken', this.getAccessTokenEvent)
        this.unityContext.on('OpenGetCreditsWindow', this.openGetCreditsWindow)
        this.unityContext
            .on('progress', this.gameLoading)
            .on('created', () => this.isUnityLoaded = true);

        window.onmessage = (message) => {
            const {eventType, ...data} = message.data

            switch (eventType) {
                case 'sendDataToUnity':
                    this.updateWallet(data)
                    this.sendDataToUnity(data)
                    break
                case 'sendAccessTokenToUnity':
                    this.sendAccessTokenToUnity(data)
                    break
                case 'sendModalStateToUnity':
                    this.sendModalStateToUnity(data)
                    break
                default:
                    return null
            }
        };
    },
    methods: {
        sendDataToUnity(data) {
            this.unityContext?.send('AccountServiceAdapter', 'ChangeWalletAddress', JSON.stringify(data))
        },
        sendAccessTokenToUnity(data) {
            this.unityContext?.send('AccountServiceAdapter', 'ChangeAccessToken', JSON.stringify(data))
        },
        sendModalStateToUnity(data) {
            this.unityContext?.send('AccountServiceAdapter', 'ChangedModalWindowState', JSON.stringify(data))
        },
        getAccessTokenEvent() {
            return {
                token: this.token,
                authToken: this.authToken,
            }
        },
        getWalletDataEvent() {
            return this.getWalletAddress();
        },
        getUsernameEvent() {
            return {
                username: this.username || '',
            }
        },
        updateWallet(wallet) {
            this.wallet = wallet
        },
        getWalletAddress() {
            return this.wallet;
        },
        gameLoading(progress) {
            this.progress = progress
        },
        setFullScreen() {
            this.unityContext.unityInstance?.SetFullscreen(1);
        },
        openGetCreditsWindow() {
            window.parent.postMessage('openGetCreditsWindow');
        }
    },
};
</script>

<style lang="scss">
.game-view {
    position: relative;
    background: #231f20;

    &__splash {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__splash-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        margin-bottom: 15px;
    }

    &__progress-text {
        margin-bottom: 15px;
        color: #fff;
        font-size: 22px;
        font-weight: bold;
    }

    &__progress {
        width: 300px;
        height: 10px;
        background: #404040;
        overflow: hidden;
    }

    &__progress-bar {
        content: '';
        display: block;
        height: 10px;
        background: linear-gradient(to top, #FFA50C, #FFCC13);
        clip-path: polygon(0 0, 100% 0, calc(100% - 3px) 100%, 0 100%);
    }

    &__loading-note {
        text-align: center;
        margin-top: 50px;
        font-size: 16px;
        color: #fff;
        max-width: 720px;
        font-weight: 900;

        p {
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.game-footer {
    display: flex;
    justify-content: flex-end;

    .expand {
        margin-top: 0.3em;
        padding: 0.4em;
        width: 2em;
        height: 2em;
        background: white;
        cursor: pointer;
    }
}

.tournament-label {
    background: #000000;
    color: white;
    padding: 1em;
}

.webgl-content * {
    border: 0;
    margin: 0;
    padding: 0;
}

</style>
