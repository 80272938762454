var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "game-view" },
      [
        _c("div", { staticClass: "game-view__splash" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSplashShowed,
                  expression: "isSplashShowed",
                },
              ],
              staticClass: "game-view__splash-content",
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "game-view__progress-text" }, [
                _c("span", { staticClass: "game-view__progress__value" }, [
                  _vm._v(_vm._s(_vm.progressPercent)),
                ]),
                _vm._v(" % "),
              ]),
              _c("div", { staticClass: "game-view__progress" }, [
                _c("div", {
                  staticClass: "game-view__progress-bar",
                  style: { width: _vm.progressPercent + "%" },
                }),
              ]),
              _vm._m(1),
            ]
          ),
        ]),
        _c("Unity", {
          attrs: {
            unity: _vm.unityContext,
            width: _vm.width,
            height: _vm.height,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "game-footer" }, [
      _c("span", { staticClass: "expand", on: { click: _vm.setFullScreen } }, [
        _c("img", {
          attrs: { src: require("../../assets/img/expand.svg?inline") },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/logo.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: { srcset: require("@/assets/img/logo.png"), type: "image/png" },
      }),
      _c("img", {
        staticClass: "game-view__logo",
        attrs: {
          src: require("@/assets/img/logo.png"),
          alt: "metafighter logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "game-view__loading-note" }, [
      _c("p", [_vm._v("Loading...MetaFighter Unleashed!")]),
      _c("p", [_vm._v("Legends Are Born in Battle. Be ready for the Fight!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }