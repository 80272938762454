// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".game-view {\n  position: relative;\n  background: #231f20;\n}\n.game-view__splash {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n.game-view__splash-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.game-view__logo {\n  margin-bottom: 15px;\n}\n.game-view__progress-text {\n  margin-bottom: 15px;\n  color: #fff;\n  font-size: 22px;\n  font-weight: bold;\n}\n.game-view__progress {\n  width: 300px;\n  height: 10px;\n  background: #404040;\n  overflow: hidden;\n}\n.game-view__progress-bar {\n  content: \"\";\n  display: block;\n  height: 10px;\n  background: linear-gradient(to top, #FFA50C, #FFCC13);\n  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 3px) 100%, 0 100%);\n          clip-path: polygon(0 0, 100% 0, calc(100% - 3px) 100%, 0 100%);\n}\n.game-view__loading-note {\n  text-align: center;\n  margin-top: 50px;\n  font-size: 16px;\n  color: #fff;\n  max-width: 720px;\n  font-weight: 900;\n}\n.game-view__loading-note p {\n  margin-bottom: 25px;\n}\n.game-view__loading-note p:last-child {\n  margin-bottom: 0;\n}\n.game-footer {\n  display: flex;\n  justify-content: flex-end;\n}\n.game-footer .expand {\n  margin-top: 0.3em;\n  padding: 0.4em;\n  width: 2em;\n  height: 2em;\n  background: white;\n  cursor: pointer;\n}\n.tournament-label {\n  background: #000000;\n  color: white;\n  padding: 1em;\n}\n.webgl-content * {\n  border: 0;\n  margin: 0;\n  padding: 0;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
